import { combineReducers } from "@reduxjs/toolkit";
import dashboardReducer from './dashboard';
import manageRosterReducer from './manage_roster';

const appReducer = combineReducers({
    dashboard: dashboardReducer,
    manageRoster: manageRosterReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
