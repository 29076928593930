import { Box } from "@mui/material";

export default function Loader() {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100vw"
            sx={{
                backgroundColor: '#F9FBFD'
            }}>
            <span class="loader"></span>
        </Box>
    )
}