import { axiosATSSInstance } from "../../../utils/axios_config"

const VehicleRosterGroupServiceURL = {
    vehicleRosterGroup: 'vehicle-roster-group',
}

export class VehicleRosterGroupService {
    static getInstance() {
        return new VehicleRosterGroupService();
    }

    async getBatchingPlants(signal) {
        return axiosATSSInstance.get(VehicleRosterGroupServiceURL.vehicleRosterGroup, { signal: signal });
    }

    async getRosterGroupByZoneID(zoneId, signal) {
        return axiosATSSInstance.get(`${VehicleRosterGroupServiceURL.vehicleRosterGroup}/${zoneId}`, { signal: signal });
    }

    errorHandler(error) {
        const config = error.config;
        const response = error.response;
        if (response && response.status === 400) {
            switch (config.url) {
                case VehicleRosterGroupServiceURL.upload:
                    return {
                        title: response.data,
                        message: ''
                    }
                default:
                    return {
                        title: 'Invalid endpoint',
                        message: 'Please check the url'
                    }
            }
        }
        else if (response && response.status === 500) {
            return {
                title: 'Internal Server Error',
                message: ''
            }
        }
        
        return {
            title: `Unknown Error (${response.status})`,
            message: ''
        }
    }
}