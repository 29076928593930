import { useEffect, useRef, useState } from "react";
import { ATSSApi } from "../../../services/atss";

export default function useRTV() {
    const [loading, setLoading] = useState(true);
    const [failed, setFailed] = useState(false);
    const [notiData, setNotiData] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [nextPageUrl, setNextPageUrl] = useState(null);

    const abortCtrlRef = useRef(new AbortController());

    useEffect(() => {
        return () => {
            const reqCtrl = abortCtrlRef.current;
            if (reqCtrl && !reqCtrl.signal.aborted) {
                reqCtrl.abort();
            }
        }
        // eslint-disable-next-line
    }, []);

    const fetchNoti = async () => {
        setLoading(true);
        setFailed(false);

        const notiCtrl = ATSSApi.getNotificationService();

        try {
            abortCtrlRef.current = new AbortController();

            const notiReq = await notiCtrl.getPriorityNotification(abortCtrlRef.current.signal);

            const filteredData = notiReq?.data?.data?.filter((data) => {
                return data?.message?.includes("Order Recommendation") || data?.message?.includes("Vehicle Assignment");
            }) ?? [];


            setNotiData(filteredData);
            if (notiReq.data.next_page_url) {
                setNextPageUrl(notiReq.data.next_page_url);
                setHasNextPage(true);
            }
        } catch (error) {
            console.error(error);
            if (!abortCtrlRef.current.signal.aborted) {
                setFailed(true);
            }
        }

        setLoading(false);
    }

    const fetcMorehNoti = async () => {
        setLoading(true);
        setFailed(false);

        const notiCtrl = ATSSApi.getNotificationService();

        try {
            abortCtrlRef.current = new AbortController();

            const notiReq = await notiCtrl.nextPageNoti(nextPageUrl, abortCtrlRef.current.signal);

            const filteredData = notiReq?.data?.data?.filter((data) => {
                return data?.message?.includes("Order Recommendation") || data?.message?.includes("Vehicle Assignment");
            }) ?? [];

            setNotiData(notiData.concat(filteredData));

            if (notiReq.data.next_page_url) {
                setHasNextPage(true);
                setNextPageUrl(notiReq.data.next_page_url);
            } else {
                setHasNextPage(false);
                setNextPageUrl(null);
            }
        } catch (error) {
            console.error(error);
            if (!abortCtrlRef.current.signal.aborted) {
                setFailed(true);
            }
        }

        setLoading(false);
    }

    const sendRating = async (payload) => {
        const notiCtrl = ATSSApi.getNotificationService();

        try {
            abortCtrlRef.current = new AbortController();

            await notiCtrl.sendRating(payload, abortCtrlRef.current.signal);
            // await notiCtrl.sendRatingWlocate(payload, abortCtrlRef.current.signal);

        } catch (error) {
            console.error(error);
            if (!abortCtrlRef.current.signal.aborted) {
                setFailed(true);
            }
        }

    }

    const retry = () => {
        fetchNoti();
    }

    return { notiData, loading, failed, hasNextPage, retry, fetcMorehNoti, sendRating };
}