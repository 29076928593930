import { createSlice } from "@reduxjs/toolkit";
import StateSelector from "../selector";
import moment from "moment";

const initialState = {
    selectedBatchingPlant: null,
    batchingPlants: [],
    rosteredVehicle: null,
    noRosterVehicles: [],
    lastSequence: 1,
    refreshOn: moment().add(5, 'minutes').toISOString()
}

export const manageRosterSlice = createSlice({
    name: 'manageRoster',
    initialState: initialState,
    reducers: {
        setRosterVehicle: (state, action) => {
            state.rosteredVehicle = action.payload ? action.payload : {
                origin_id: state.selectedBatchingPlant.ZoneID,
                name: state.selectedBatchingPlant.ShipToCode,
                current_sequence: [],
                roster_sequence: []
            };
            const rosterSequence = state.rosteredVehicle.roster_sequence;
            if (rosterSequence.length > 0) state.lastSequence = rosterSequence.at(-1).sequence;
        },
        setRosterLastSequence: (state, action) => {
            state.lastSequence = action.payload;
        },
        setNoRosterVehicles: (state, action) => {
            if (action.payload[0].VehicleID) {
                let tempArray = []
                action.payload.forEach(data => {
                    tempArray.push({
                        alert_level: data.AlertLevel,
                        alert_zones: data.AlertZones,
                        battery: data.Battery,
                        bin_status_id: data.BinStatusId,
                        bypass_pin: data.BypassPin,
                        cameras: data.Cameras,
                        cancelled_job_id: data.CancelledJobId,
                        cancelled_timestamp: data.CancelledTimestamp,
                        category: data.Category,
                        category_ex: data.CategoryEx,
                        category_id: data.CategoryID,
                        companies: data.Companies,
                        company: data.Company,
                        company_id: data.CompanyID,
                        completed_job_id: data.CompletedJobId,
                        completed_timestamp: data.CompletedTimestamp,
                        created_at: data.CreatedAt,
                        device: data.Device,
                        device_iccid: data.DeviceICCID,
                        device_id: data.DeviceID,
                        device_phone: data.DevicePhone,
                        device_type: data.DeviceType,
                        device_type_id: data.DeviceTypeID,
                        division: data.Division,
                        division_id: data.DivisionID,
                        door_status: data.DoorStatus,
                        driver: data.Driver,
                        driver_app_status: data.DriverAppStatus,
                        driver_id: data.DriverID,
                        driver_num: data.DriverNum,
                        driver_num2: data.DriverNum2,
                        drivers: data.Drivers,
                        erp_system: data.ERPSystem,
                        fuel_capacity: data.FuelCapacity,
                        fuel_drop: data.FuelDrop,
                        fuel_level: data.FuelLevel,
                        fuel_prev: data.FuelPrev,
                        gps: data.GPS,
                        group: data.Group,
                        group_id: data.GroupID,
                        has_joint_tickets: data.HasJointTickets,
                        home_plant: data.HomePlant,
                        home_plant_id: data.HomePlantID,
                        io_pins: data.IOPins,
                        icon: data.Icon,
                        id: data.ID,
                        idling_limit: data.IdlingLimit,
                        ignition: data.Ignition,
                        image: data.Image,
                        internal_remarks: data.InternalRemarks,
                        interval: data.Interval,
                        io_pins: data.IOPins,
                        job_status: data.JobStatus,
                        job_status_id: data.JobStatusID,
                        joint_tickets: data.JointTickets,
                        last_arrival: data.LastArrival,
                        last_event_id: data.LastEventID,
                        last_event_status: data.LastEventStatus,
                        last_full_load: data.LastFullLoad,
                        last_idle: data.LastIdle,
                        last_ignition: data.LastIgnition,
                        last_job_id: data.LastJobID,
                        last_mileage: data.LastMileage,
                        last_move: data.LastMove,
                        last_order_id: data.LastOrderID,
                        last_pos: data.LastPos,
                        last_pos_ex: data.LastPosEx,
                        last_pos_id: data.LastPosID,
                        last_pressure: data.LastPressure,
                        last_update: data.LastUpdate,
                        last_zones: data.LastZones,
                        load_capacity: data.LoadCapacity,
                        mileage: data.Mileage,
                        mileage_alert: data.MileageAlert,
                        mileage_base: data.MileageBase,
                        mileage_device: data.MileageDevice,
                        mpob: data.Mpob,
                        multiple_job: data.MultipleJob,
                        name: data.Name,
                        phone: data.Phone,
                        positions: data.Positions,
                        power_takeoff: data.PowerTakeoff,
                        pressure: data.Pressure,
                        pressure_max: data.PressureMax,
                        remarks: data.Remarks,
                        sensors: data.Sensors,
                        sequence: data.Sequence,
                        server_ip: data.ServerIP,
                        server_port: data.ServerPort,
                        service_start: data.ServiceStart,
                        sgs_status: data.SGStatus,
                        speed_limit: data.SpeedLimit,
                        staging_grounds: data.StagingGrounds,
                        status: data.Status,
                        status_ex: data.StatusEx,
                        status_id: data.StatusID,
                        temperature: data.Temperature,
                        ticket_info: data.TruckInfo,
                        ticket_num: data.TicketNum,
                        track_holidays: data.TrackHolidays,
                        track_week: data.TrackWeek,
                        type_id: data.TypeID,
                        under_maintenance: data.UnderMaintenance,
                        updated_at: data.UpdateAt,
                        vehicle_id: data.VehicleID,
                        waiting_duration: data.WaitingDuration,
                        wireless: data.Wireless,
                        zone_id_limit: data.ZoneIDLimit,
                    })
                });

                state.noRosterVehicles = tempArray;
            } else {
                state.noRosterVehicles = action.payload;
            }
            state.refreshOn = moment().add(5, 'minutes').toISOString();
        },
        updateRosterRefreshTime: (state) => {
            state.refreshOn = moment().add(5, 'minutes').toISOString();
        },
        setRosterBatchingPlants: (state, action) => {
            state.batchingPlants = action.payload;
        },
        setRosterSelectedPlant: (state, action) => {
            state.selectedBatchingPlant = action.payload;
        },
        updateNoRosterVehicles: (state, action) => {
            const noRosterVehicles = [...state.noRosterVehicles];
            action.payload.forEach(val => {
                const vehicle = noRosterVehicles.find(f => f.vehicle_id === val.vehicle_id);
                if (vehicle) {
                    const index = noRosterVehicles.indexOf(vehicle);
                    noRosterVehicles[index] = val;
                }
            });
            state.noRosterVehicles = noRosterVehicles;
        },
        resetRosterState: (state, action) => {
            return initialState;
        }
    }
});

export const {
    setRosterVehicle,
    setNoRosterVehicles,
    setRosterBatchingPlants,
    setRosterSelectedPlant,
    setRosterLastSequence,
    updateRosterRefreshTime,
    updateNoRosterVehicles,
    resetRosterState,
} = manageRosterSlice.actions;

export const manageRosterSelector = StateSelector('manageRoster');

export default manageRosterSlice.reducer