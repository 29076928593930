import {
    Box,
    Button,
    IconButton,
    Paper,
    Stack,
    useTheme,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { ATSSStorage, ATSSStorageKeys } from '../utils/atss_storage';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ATSSRoutes } from '../App';
import { validateAuth } from '../utils/route_guard';
import { Suspense, useEffect, useState } from 'react';
import Loader from './Loader';
import Notification from './Notification';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { dashboardSelector, resetDashboardState, resetInActive, setDashboardBatchingPlant } from "../redux/reducers/dashboard";
import { manageRosterSelector, resetRosterState, setRosterLastSequence, setRosterSelectedPlant } from "../redux/reducers/manage_roster";

export default function NavigationBar() {
    const storage = new ATSSStorage();
    const { batchingPlants, selectedBatchingPlant } = useSelector(manageRosterSelector(['batchingPlants', 'selectedBatchingPlant']), shallowEqual);
    const { origins, batchingPlant, loading } = useSelector(dashboardSelector(['origins', 'batchingPlant', 'loading']), shallowEqual);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [notiAnchorEl, setNotiAnchorEl] = useState(null);

    useEffect(() => {
        validateAuth(navigate);
        // eslint-disable-next-line
    }, []);

    const handleLogout = () => {
        storage.removeItem(ATSSStorageKeys.account);
        storage.removeItem(ATSSStorageKeys.accountInfo);
        storage.removeSessionItem(ATSSStorageKeys.manageBatchingPlant);
        storage.removeSessionItem(ATSSStorageKeys.dashboardBatchingPlant);

        navigate(ATSSRoutes.login);

        dispatch(resetDashboardState());
        dispatch(resetRosterState());
    }

    const handleOpenLogoutModal = () => {
        setOpenLogoutModal(true);
    }

    const handleCloseLogoutModal = () => {
        setOpenLogoutModal(false);
    }

    const handleNotification = (event) => {
        setNotiAnchorEl(event.currentTarget);
        setOpenNotification(!openNotification);
    }

    const handleCloseNotification = () => {
        setOpenNotification(false);
        setNotiAnchorEl(null);
    }

    const isCurrentPath = (pathName) => {
        return location.pathname === pathName;
    }

    return (
        <>
            <Paper
                component={'nav'}
                sx={{
                    backgroundColor: '#EDEDED',
                    width: '100%',
                    height: '50px',
                }}
                square
                elevation={4}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="5px 5px"
                >
                    {isCurrentPath(ATSSRoutes.manageRoster) ?
                        <FormControl size="small" sx={{ marginTop: '10px' }}>
                            <InputLabel id="select-label" sx={{ fontSize: '10px' }}>Batching Plant</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                label="Batching Plant"
                                value={storage.getSessionItem(ATSSStorageKeys.manageBatchingPlant)
                                    ? storage.getSessionItem(ATSSStorageKeys.manageBatchingPlant)
                                    : selectedBatchingPlant
                                    ?? ''
                                }
                                onChange={(e) => {
                                    storage.setSessionItem(ATSSStorageKeys.dashboardBatchingPlant, {
                                        ShipToCode: e.target.value.zone_code,
                                        ZoneID: e.target.value.zone_id,
                                        Name: e.target.value.name,
                                    });
                                    storage.setSessionItem(ATSSStorageKeys.manageBatchingPlant, {
                                        name: e.target.value.name,
                                        zone_code: e.target.value.zone_code,
                                        zone_id: e.target.value.zone_id,
                                    });
                                    dispatch(setRosterLastSequence(1));
                                    dispatch(setRosterSelectedPlant(batchingPlants.find(f => f.zone_id === e.target.value.zone_id)))
                                }}
                                renderValue={(e) => {
                                    return e.name
                                }}
                                sx={{ minWidth: '120px', fontSize: '10px' }}
                            >
                                {batchingPlants && batchingPlants.map(plant => (
                                    <MenuItem key={plant.zone_id} value={plant} sx={{ fontSize: '10px' }}>
                                        {plant.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        :
                        <FormControl size="small" sx={{ marginTop: '10px' }}>
                            <InputLabel id="select-label" sx={{ fontSize: '10px' }}>Batching Plant</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                label="Batching Plant"
                                value={
                                    storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant) !== null && storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant) !== undefined
                                        ? storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant)?.Name
                                        : batchingPlant !== null && batchingPlant !== undefined
                                            ? batchingPlant?.Name
                                            : ''
                                            ?? ''
                                }
                                onChange={(e) => {
                                    const selectedOrigin = origins.find(o => o.Name === e.target.value);

                                    storage.setSessionItem(ATSSStorageKeys.dashboardBatchingPlant, {
                                        ShipToCode: selectedOrigin.ShipToCode,
                                        ZoneID: selectedOrigin.ZoneID,
                                        Name: selectedOrigin.Name,
                                    });
                                    storage.setSessionItem(ATSSStorageKeys.manageBatchingPlant, {
                                        name: selectedOrigin.Name,
                                        zone_code: selectedOrigin.ShipToCode,
                                        zone_id: selectedOrigin.ZoneID,
                                    });
                                    dispatch(resetInActive());
                                    dispatch(setDashboardBatchingPlant(origins.find(f => f.ShipToCode === selectedOrigin.ShipToCode)));
                                }}
                                style={{ minWidth: '120px', fontSize: '10px' }}
                            >
                                {origins && origins.map(origin => (
                                    <MenuItem key={origin.ShipToCode} value={origin.Name} sx={{ fontSize: '10px' }}>
                                        {origin.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                    <span>
                        <Button variant={isCurrentPath(ATSSRoutes.dashboard) ? 'outlined' : 'text'} disableRipple={isCurrentPath(ATSSRoutes.dashboard)} startIcon={<DashboardIcon />}
                            onClick={() => navigate(ATSSRoutes.dashboard)} sx={{
                                padding: '0 20px',
                                height: '35px',
                                marginRight: '5px',
                                fontSize: '10px'
                            }}>
                            Dashboard
                        </Button>
                        <Button variant={isCurrentPath(ATSSRoutes.manageRoster) ? 'outlined' : 'text'} disableRipple={isCurrentPath(ATSSRoutes.manageRoster)} startIcon={<ManageAccountsIcon />}
                            onClick={() => navigate(ATSSRoutes.manageRoster)} sx={{
                                padding: '0 20px',
                                height: '35px',
                                marginRight: '5px',
                                fontSize: '10px'
                            }}>
                            Manage Roster
                        </Button>
                        {openNotification ?
                            <IconButton onClick={handleNotification} sx={{ marginTop: '-3px' }}>
                                <NotificationsIcon sx={{ fontSize: '25px' }} color='primary' />
                            </IconButton>
                            :
                            <IconButton onClick={handleNotification} sx={{ marginTop: '-3px' }}>
                                <NotificationsNoneOutlinedIcon sx={{ fontSize: '25px' }} color='primary' />
                            </IconButton>
                        }
                        <IconButton onClick={handleOpenLogoutModal} sx={{ marginTop: '-3px' }}>
                            <PowerSettingsNewOutlinedIcon sx={{ fontSize: '25px' }} htmlColor={theme.palette.error.main} />
                        </IconButton>
                    </span>
                </Stack>
            </Paper>
            <Box padding={theme.spacing(2)} height="calc(100vh - 45px)" sx={{ position: 'relative' }} >
                <Suspense fallback={<Loader />}>
                    <Outlet />
                </Suspense>
            </Box>
            <Dialog
                open={openLogoutModal}
                onClose={handleCloseLogoutModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Typography variant='h5'>
                        Confirm Logout
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant='body1'>
                            Are you sure you want to logout?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogout} autoFocus>
                        <Typography variant='button'>
                            OK
                        </Typography>
                    </Button>
                    <Button onClick={handleCloseLogoutModal}>
                        <Typography variant='button'>
                            Cancel
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            {openNotification &&
                <Notification open={openNotification} handleClose={handleCloseNotification} anchorEl={notiAnchorEl} />
            }
        </>
    )
}