import { axiosATSSInstance, axiosIntegrationInstance } from "../../../utils/axios_config"

const NotificationServiceURL = {
    getNoti: 'notifications',
    sendRating: 'feedback',
    sendRatingWlocate: 'Ranking/Feedback'
}

export class NotificationService {
    static getInstance() {
        return new NotificationService();
    }

    async getPriorityNotification(signal) {
        return axiosATSSInstance.get(`${NotificationServiceURL.getNoti}`, { signal: signal });
    }

    async sendRating(payload, signal){
        return axiosATSSInstance.post(`${NotificationServiceURL.sendRating}`, payload, { signal: signal });
    }

    async sendRatingWlocate(payload, signal){
        return axiosIntegrationInstance.put(`${NotificationServiceURL.sendRatingWlocate}`, payload, { signal: signal });
    }

    async nextPageNoti(url, signal){
        return axiosATSSInstance.get(url, { signal: signal });
    }


    errorHandler(error) {
        const config = error.config;
        const response = error.response;
        if (response && response.status === 400) {
            switch (config.url) {
                case NotificationServiceURL.getNoti:
                    return {
                        title: response.data,
                        message: ''
                    }
                default:
                    return {
                        title: 'Invalid endpoint',
                        message: 'Please check the url'
                    }
            }
        }
        else if (response && response.status === 500) {
            return {
                title: 'Internal Server Error',
                message: ''
            }
        }
        
        return {
            title: `Unknown Error (${response.status})`,
            message: ''
        }
    }
}