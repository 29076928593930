import { createSlice } from "@reduxjs/toolkit";
import StateSelector from "../selector";
import { ATSSStorage, ATSSStorageKeys } from '../../utils/atss_storage';

const initialState = {
    origins: [],
    orders: [],
    jobs: [],
    vehiclePriority: [],
    orderPriority: [],
    batchingPlant: null,
    silentRefresh: 0,
    loading: true,
    inActive: 0,
    vehicleAvailability: [],
    selectedOrder: [],
    keywordSearch: "",
}

const storage = new ATSSStorage();

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        setDashboardOrigins: (state, action) => {
            const origins = action.payload.filter(f => f.ZoneTypeEx === 1);
            state.origins = origins;
            if (storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant) !== null && storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant) !== undefined) {
                state.batchingPlant = storage.getSessionItem(ATSSStorageKeys.dashboardBatchingPlant);
            } else if (origins.length > 0) {
                state.batchingPlant = origins[0];
            }
        },
        setDashboardOrders: (state, action) => {
            state.orders = action.payload;
        },
        setDashboardJobs: (state, action) => {
            state.jobs = action.payload;
        },
        setDashboardBatchingPlant: (state, action) => {
            state.batchingPlant = action.payload;
            state.selectedOrder = [];
        },
        setDashboardLoading: (state, action) => {
            state.loading = action.payload;
        },
        setDashboardVehiclePriority: (state, action) => {
            state.vehiclePriority = action.payload;
        },
        setDashboardSilentRefresh: (state, action) => {
            state.silentRefresh = action.payload ?? (state.silentRefresh + 1);
            state.inActive++;
        },
        setDashboardOrderPriority: (state, action) => {
            state.orderPriority = action.payload;
        },
        resetInActive: (state, action) => {
            state.inActive = 0;
        },
        setDashboardVehicleAvailability: (state, action) => {
            state.vehicleAvailability = action.payload;
        },
        resetDashboardState: (state, action) => {
            return initialState;
        },
        setSelectedOrders: (state, action) => {
            state.selectedOrder = action.payload;
        },
        setKeywordSearch: (state, action) => {
            state.keywordSearch = action.payload;
        }
    }
});

export const {
    setDashboardOrigins,
    setDashboardOrders,
    setDashboardBatchingPlant,
    setDashboardJobs,
    setDashboardLoading,
    setDashboardVehiclePriority,
    setDashboardSilentRefresh,
    setDashboardOrderPriority,
    resetInActive,
    setDashboardVehicleAvailability,
    resetDashboardState,
    setSelectedOrders,
    setKeywordSearch,
} = dashboardSlice.actions;

export const dashboardSelector = StateSelector('dashboard');

export default dashboardSlice.reducer