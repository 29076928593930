import { axiosATSSInstance } from "../../../utils/axios_config"

const OrderServiceURL = {
    orderPriority: 'order/priority',
}

export class OrderService {
    static getInstance() {
        return new OrderService();
    }

    async getPriorityOrders(zoneID, keyword, signal) {
        if(keyword && keyword !== ""){
            return axiosATSSInstance.get(`${OrderServiceURL.orderPriority}/${zoneID}?order_code=${keyword}`, { signal: signal });
        }else{
            return axiosATSSInstance.get(`${OrderServiceURL.orderPriority}/${zoneID}`, { signal: signal });
        }
    }

    async nextPageOrder(url, signal){
        return axiosATSSInstance.get(url, { signal: signal });
    }

    errorHandler(error) {
        const config = error.config;
        const response = error.response;
        if (response && response.status === 400) {
            switch (config.url) {
                case OrderServiceURL.orderPriority:
                    return {
                        title: response.data,
                        message: ''
                    }
                default:
                    return {
                        title: 'Invalid endpoint',
                        message: 'Please check the url'
                    }
            }
        }
        else if (response && response.status === 500) {
            return {
                title: 'Internal Server Error',
                message: ''
            }
        }
        
        return {
            title: `Unknown Error (${response.status})`,
            message: ''
        }
    }
}