import { axiosATSSInstance } from "../../../utils/axios_config"

const VehicleRosterServiceURL = {
    upload: 'vehicle-roster',
    revert: 'vehicle-roster/revert'
}

export class VehicleRosterService {
    static getInstance() {
        return new VehicleRosterService();
    }

    async uploadVehicleRoster(payload, signal) {
        return axiosATSSInstance.post(VehicleRosterServiceURL.upload, payload, { signal: signal });
    }

    async revertVehicleRoster(payload, signal) {
        return axiosATSSInstance.post(VehicleRosterServiceURL.revert, payload, { signal: signal });
    }

    errorHandler(error) {
        const config = error.config;
        const response = error.response;
        if (response && response.status === 400) {
            switch (config.url) {
                case VehicleRosterServiceURL.upload:
                    return {
                        title: response.data,
                        message: ''
                    }
                default:
                    return {
                        title: 'Invalid endpoint',
                        message: 'Please check the url'
                    }
            }
        }
        else if (response && response.status === 500) {
            return {
                title: 'Internal Server Error',
                message: ''
            }
        }
        
        return {
            title: `Unknown Error (${response.status})`,
            message: ''
        }
    }
}