import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Rating, Typography, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import Swal from "sweetalert2";

const RatingModal = ({ isOpen, closeModal, data, sendRating }) => {

    const labels = {
        1: 'Super Weak',
        2: 'Selection not good',
        3: 'Site / Order Depended ',
        4: 'Accepted',
        5: 'Most Accurate',
    };

    const [rating, setRating] = useState(3);
    const [ratingComment, setRatingComment] = useState('');
    const [hover, setHover] = useState(-1);

    const handleSendRating = () => {
        Swal.fire({
            icon: 'info',
            title: "Sending Rating",
            text: 'Please wait...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    
                    let payload = {
                        id: data.RankID,
                        relevance: rating/5,
                        remarks: ratingComment,
                        timestamp: new Date().toISOString()
                    }

                    await sendRating(payload)
                    closeModal();

                    Swal.fire({
                        icon: 'success',
                        title: 'Thank you for rating our recommendation!'
                    });
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to send rating.',
                        text: 'Please, try again.'
                    });
                }
            }
        });
    }

    const parseDate = (date) => {
        const tempDate = new Date(date);
        tempDate.setHours(tempDate.getHours());
        const month = tempDate.toLocaleString('default', { month: 'short' });
        const day = tempDate.toLocaleString('default', { day: '2-digit' })
        const time = tempDate.toLocaleString('default', { timeStyle: 'short', hour12: false })
        const year = tempDate.toLocaleString('default', { year: 'numeric' })

        return `${day}-${month}-${year}, ${time}`
    }

    return (
        <Dialog
            open={isOpen}
        >
            <DialogTitle>
                {data?.FeedbackType ?? 'Recommendation Rating'}
            </DialogTitle>
            <DialogContent sx={{ padding: '40px, 40px, 0px, 40px' }}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    sx={{ marginBottom: '20px' }}
                >
                    <Typography>
                        Vehicle: {data?.Vehicle ?? 'No Vehicle Name'}
                    </Typography>
                    <Typography>
                        Order Code: {data?.OrderCode ?? 'No Order Code'}
                    </Typography>
                    {data?.TicketNum &&
                        <Typography>
                            Ticket Number: {data?.TicketNum ?? 'No Ticket Number'}
                        </Typography>
                    }
                    {data?.Customer &&
                        <Typography>
                            Customer: {data?.Customer ? data.Customer : 'No Customer'}
                        </Typography>
                    }
                    {data?.Destination &&
                        <Typography>
                            Destination: {data?.Destination ? data.Destination : 'No Destination'}
                        </Typography>
                    }
                    <Typography>
                        Started At : {data?.Started ? parseDate(data.Started) : 'No Started date'}
                    </Typography>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography variant="h6">
                        {data?.Message ?? 'How will you rate our recommendation?'}
                    </Typography>
                    <Rating
                        max={5}
                        size="large"
                        value={rating}
                        onChange={(event, newValue) => {
                            setRating(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                            setHover(newHover);
                        }}
                    />
                    <Typography variant="h6" sx={{ minHeight: '20px' }}>
                        {labels[hover !== -1 ? hover : rating]}
                    </Typography>
                </Stack>
                <TextField
                    label="Comment/Remarks"
                    fullWidth
                    multiline
                    rows={4}
                    value={ratingComment}
                    onChange={(event) => {
                        setRatingComment(event.target.value);
                    }}
                    sx={{marginTop: '20px'}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeModal()}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleSendRating}>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default RatingModal;