import { ATSSRoutes } from "../App";
import { ATSSStorage, ATSSStorageKeys } from "./atss_storage";

export function validateAuth(navigate, isValid) {
    const storage = new ATSSStorage();
    const account = storage.getItem(ATSSStorageKeys.account);
    if (account) {
        if (isValid) isValid();
        else return;
    }
    else navigate(ATSSRoutes.login);
}